import React, { useEffect, useContext, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Box, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import {
  CommonCard,
  Header,
  BillingTable,
  CompanyDetails,
  AccountEngagementHeader
} from './particles';

import { ApplicationContext, TableContext } from '@particles';
import {
  callGetCompanyUsers,
  callGetLicenseLatest,
  callGetTotalUsers,
  callGetUser,
  callDeleteLicense,
  callGetTeamsList
} from '@api';
import { TextView, Button, AlertPopUp, Alert } from '@atoms';
import { EditAccountModal, TeamTable, CompanyTeam } from '@organisms';
import { AddUserModal, AddCompanyTeam } from '@pages';
import { UploadUserModal } from '@pages';
import { toast } from 'react-toastify';

interface BillingDetailsProps extends RouteComponentProps {
  id?: string;
  type: 'superadmin' | 'admin';
}

const BillingDetails: React.FC<BillingDetailsProps> = (props: BillingDetailsProps) => {
  const { state } = useContext(ApplicationContext);
  const { dispatch: dispatchTable } = useContext(TableContext);
  const { type, id } = props;
  const isSuperAdmin = type === 'superadmin';
  const companyId = isSuperAdmin ? id : state.user?.companyId;

  const [total, setTotal] = useState({
    licensed: 0
  });

  const [teamList, setTeamList] = useState<Team[]>([]);
  const [selectedTeamForFilter, setSelectedTeamForFilter] = useState<Team>();
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const [openAddTeam, setOpenAddTeam] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openEdit, setEdit] = useState<boolean>(false);
  const [openDelete, setDelete] = useState<boolean>(false);
  const [license, setLicense] = useState<License>();
  const [activeUser, setActiveUser] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [alert, setAlert] = useState<
    { title: string; message: string; type: 'success' | 'error' } | undefined
  >(undefined);

  const [openUploadUser, setOpenUploadUser] = useState<boolean>(false);

  const apiGetUser = async (email: string) => {
    if (!companyId) {
      return;
    }
    callGetUser({ companyId, email }).then((res) => {
      if (res.code === 200) {
        setUser(res.data);
      }
    });
  };

  const apiDeleteAccount = async () => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    callDeleteLicense(companyId).then((res) => {
      if (res.code === 200) {
        navigate('/allaccount');
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const api = async () => {
      if (companyId) {
        setLoading(true);
        const { code: currentLicenseCode, data: currentLicenseData } = await callGetLicenseLatest({
          companyId
        });
        if (currentLicenseCode == 200 && currentLicenseData) {
          setTotal(currentLicenseData.data.total);
          setLicense(
            currentLicenseData.data.accountDetails.length > 0
              ? currentLicenseData.data.accountDetails[0]
              : undefined
          );
        }

        const { code: codeUser, data: dataUser } = await callGetTotalUsers({ companyId });
        if (codeUser == 200) {
          setActiveUser(dataUser?.data?.total?.active ?? 0);
        }

        const { data, code } = await callGetCompanyUsers({
          companyId,
          pageNumber: 1,
          pageSize: 1
        });
        if (code == 200 && data && data.totalCount > 0) {
          const agent = data.data[0];
          await apiGetUser(agent.email);
        }

        await teamApi();

        setLoading(false);
      } else {
        setAlert({
          title: 'CompanyId missing',
          message: 'contact your admin person',
          type: 'error'
        });
        setTimeout(() => {
          setAlert(undefined);
        }, 3000);
      }
    };

    api();
    return () => {
      dispatchTable({
        type: `set`,
        key: `teamPagination`,
        value: { page: 1, perPage: 10 }
      });
      dispatchTable({
        type: `set`,
        key: `billingPagination`,
        value: { page: 1, perPage: 10 }
      });
    };
  }, [companyId]);

  const teamApi = () => {
    if (companyId) {
      callGetTeamsList({ companyId }).then((res) => {
        setTeamList(res.data ?? []);
      });
    }
  };

  if (!companyId) {
    return (
      <Box sx={{ m: 2 }}>
        <TextView>CompanyID is missing</TextView>
      </Box>
    );
  }

  const handleChangeTeamSelected = (event: SelectChangeEvent) => {
    const _teamData = teamList.find((item) => item.id === event.target.value);
    setSelectedTeamForFilter(_teamData);
  };

  return (
    <Box sx={{ m: 2 }}>
      {loading && <LinearProgress />}
      <Box sx={{ mt: 1, mb: 3 }}>
        <Header
          title={isSuperAdmin ? license?.name ?? '' : ''}
          isSuerAdmin={isSuperAdmin}
          onPressUpdate={() => {
            setEdit(true);
          }}
          onPressDelete={() => {
            setDelete(true);
          }}
        />
      </Box>
      {!loading && (
        <Box>
          <Box sx={{ mb: 3 }}>{isSuperAdmin && <CompanyDetails user={user} />}</Box>
          <CommonCard licensed={total.licensed} active={activeUser} license={license} />

          {isSuperAdmin && (
            <Box mt={3}>
              <AccountEngagementHeader
                title={'Account Engagement'}
                teamList={teamList}
                companyId={companyId}
              />
            </Box>
          )}

          <BillingTable
            isSuperAdmin={isSuperAdmin}
            licensedCount={total.licensed}
            activeCount={activeUser}
            license={license}
            companyId={companyId}
          />

          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextView display={'initial'} $fontSize={30}>
              All users
            </TextView>
            <Box sx={{ flex: 1 }} />
            <TextView $fontWeight={400} $fontSize={14} mr={1}>
              Select Team
            </TextView>
            <Select
              value={selectedTeamForFilter?.id ?? '0'}
              onChange={handleChangeTeamSelected}
              displayEmpty
              size={'small'}
              sx={{ backgroundColor: '#ffffff', fontSize: 14, mr: 1 }}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem key={0} value={'0'}>
                {'All Team'}
              </MenuItem>
              {teamList.map((item) => {
                return (
                  <MenuItem key={item.id} value={`${item.id}`}>
                    {item.teamName}
                  </MenuItem>
                );
              })}
            </Select>
            <Button onClick={() => setOpenAddUser(true)}>{'Add User'}</Button>
            <Button onClick={() => setOpenUploadUser(true)} sx={{ ml: 1 }}>
              Upload Users
            </Button>

            <UploadUserModal
              companyId={companyId}
              open={openUploadUser}
              onClose={() => setOpenUploadUser(false)}
              onSuccess={() => {
                setRefresh(Date.now());
                toast.success('User invited successfully');
                setOpenUploadUser(false);
              }}
            />
          </Box>

          <TeamTable
            companyId={companyId}
            refresh={refresh}
            isSuperAdmin={isSuperAdmin}
            selectedTeam={selectedTeamForFilter}
            onEdit={(agent: Agent) => {
              setSelectedAgent(agent);
              setOpenAddUser(true);
            }}
          />

          <EditAccountModal
            user={user}
            open={openEdit}
            onClose={() => setEdit(false)}
            onSuccess={(user) => {
              setEdit(false);
              setUser(user);
            }}
          />

          <AddUserModal
            open={openAddUser}
            selectedAgent={selectedAgent}
            companyId={companyId}
            onClose={() => {
              setOpenAddUser(false);
              setSelectedAgent(undefined);
              setRefresh(Date.now());
            }}
            onSuccess={() => {
              setOpenAddUser(false);
              setSelectedAgent(undefined);
              setRefresh(Date.now());
            }}
          />

          <AlertPopUp
            open={openDelete}
            title={'Delete'}
            message={'Are you sure, you want to delete account?'}
            onSuccess={() => {
              setDelete(false);
              apiDeleteAccount();
            }}
            onClose={() => {
              setDelete(false);
            }}
          />

          {/* START:Team Block*/}

          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextView display={'initial'} $fontSize={30}>
              All Teams
            </TextView>
            <Box sx={{ flex: 1 }} />
            <Button onClick={() => setOpenAddTeam(true)}>{'Add Team'}</Button>
          </Box>

          <CompanyTeam
            companyId={companyId}
            refresh={refresh}
            isSuperAdmin={isSuperAdmin}
            onEdit={(team: Team) => {
              setSelectedTeam(team);
              setOpenAddTeam(true);
            }}
          />

          <AddCompanyTeam
            open={openAddTeam}
            selectedTeam={selectedTeam}
            companyId={companyId}
            onClose={() => {
              setOpenAddTeam(false);
              setSelectedTeam(undefined);
            }}
            onSuccess={() => {
              setOpenAddTeam(false);
              setSelectedTeam(undefined);
              teamApi();
              setRefresh(Date.now());
            }}
          />

          {alert && <Alert severity={alert.type} title={alert.title} message={alert.message} />}

          {/* END: TEAM BLOCK */}
        </Box>
      )}
    </Box>
  );
};

export default BillingDetails;
